import type { FormItemRule } from 'element-plus';
interface Item {
  label: string;
  length: number;
  minLength?: number;
  maxLength?: number;
  notRequired: boolean;
}

interface ChannelData {
  game: string;
  cname: string;
}

export default function UseLoginPay () {
  const rules = (item: Item): FormItemRule|FormItemRule[]|undefined => {
    let required = { required: true, message: `请输入${item.label}`, trigger: 'change' };
    let length = {
      min: item.minLength ? item.minLength : item.length,
      max: item.maxLength ? item.maxLength : item.length,
      message: `${item.label}参数${item.maxLength ? '最大' : ''}长度为${item.length || item.maxLength}`,
      trigger: 'change'
    };
    if (item.notRequired && !item.length && !item.maxLength) {
      return undefined;
    } else if (item.notRequired && (item.length || item.maxLength)) {
      return length;
    } else if (item.length || item.maxLength) {
      return [required, length];
    } else {
      return required;
    }
  };

  const createCnames = (data: ChannelData[]) => {
    let obj = {} as any;
    let result = '';
    try {
      data && data.forEach(item => {
        if (obj[item.game]) {
          obj[item.game].push(item.cname);
        } else {
          obj[item.game] = [item.cname];
        }
      });
      if (Object.keys(obj).length) {
        for (let key in obj) {
          result += `，${key}(${obj[key].join(',')})`;
        }
      }
    } catch (error) {

    }
    return result;
  };

  return {
    rules,
    createCnames
  };
}
